<template>
  <div class="disclosure">
    <h1>2021 ID.4 Charging Plan</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color">Effective as of February 15, 2021</p>
    <p>
      Electrify America, LLC (“Electrify America”) and Volkswagen Group of America, Inc. (“Volkswagen”) are pleased to
      provide the 2021 ID.4 Charging Plan (“Plan”) to drivers of new 2021 Volkswagen ID.4 vehicles purchased from an
      authorized Volkswagen dealer. This Plan provides 36 months of complimentary DC fast (CCS) and Level 2 charging
      sessions for your 2021 Volkswagen ID.4, starting from the date of vehicle purchase, at no additional cost. By
      clicking “Agree” below, you agree to participate in this Plan, subject to your compliance with all Electrify
      America terms applicable to the Plan (including the terms contained in this disclosure and the app’s Terms of Use,
      which together govern your use of the Plan).
    </p>
    <p>
      Participation requires an Electrify America account, app, and enrollment in the ID.4 Charging Plan. Your mobile
      carrier’s normal messaging, data, and other rates and fees will apply to your use of the Electrify America mobile
      application.
    </p>
    <p>
      Upon your purchase of the vehicle and acceptance of the terms of this disclosure, you will be enrolled in this
      Plan, which you can access through Electrify America’s network of electric vehicle charging stations.
    </p>
    <p>
      After enrollment, you can redeem your complimentary charging during your initiation of a charging session at any
      Electrify America charging station, exclusively by using the Electrify America app “Swipe to start” or NFC Pass
      feature with the appropriate plan selected. This complimentary charging may not be initiated using the interface
      screen or buttons on the charger.
    </p>
    <p>
      There is no additional fee, purchase, or subscription required for your access to this Plan. Subject to the
      restrictions below, there is no limit on the number of eligible charging sessions or the kWh amount of charging
      available in each session. However, please be aware that frequent and consecutive charging can permanently
      decrease battery capacity, which may not be covered your vehicle’s warranty.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PLAN:</strong>
    </p>
    <p>
      You may <strong>only</strong> use this Plan for charging the ID.4 whose VIN you submitted when signing up for this
      Plan on your Electrify America account.
      <strong>No other vehicle is eligible to receive charging under this Plan through your account</strong>, whether
      owned by you or others.
    </p>
    <p>
      Complimentary charging time <strong>does not include</strong> any idle fees and their associated applicable taxes
      assessed at the charging station.
      <strong
        >You will be responsible for paying such fees through your account if you incur them during a charging
        session.</strong
      >
      >
    </p>
    <p>
      <strong
        >This Plan is not available for any vehicles in commercial use, such as ridesharing or taxi services.</strong
      >
    </p>
    <p>
      You must follow all instructions and heed all warnings contained in all product, vehicle, safety, and technical
      documentation included with the ID.4 and follow all instructions and safety recommendations posted at the
      Electrify America charging station when charging. Please see your Owner’s Manual for additional information.
    </p>
    <p>
      You must use the correct connector type and charge at the correct power level for the ID.4 at the charging
      station.
    </p>
    <p>
      Once the 36-month term of this Plan expires, you will not be able to reload or add more credit to this Plan.
      Please refer to the information on the <strong>“Plan Details”</strong> section of the app to keep track of the
      amount of time remaining in this Plan.
    </p>
    <p>
      This Plan does not provide any credit that is returnable or redeemable for any cash value, and may not be sold,
      loaned, or otherwise distributed to any third party for any reason. You may not transfer any portion of the Plan
      to any other Electrify America account.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link>, which
      are incorporated herein by reference, and that your use of the Electrify America mobile app is subject to the
      Terms of Use accessible by navigating to the <strong>Profile</strong> icon in top right hand corner of map,
      selecting <strong>“Legal”</strong> and then selecting
      <router-link class="medium" :to="{ name: 'terms-en' }">“Terms of Use”</router-link> &amp;
      <router-link class="medium" :to="{ name: 'privacy-en' }">“Privacy Policy.”</router-link>
      Please read these Terms of Use documents carefully for information about how you can pay through your account for
      idle fees and applicable taxes as well as for charging session time outside of the complimentary charging offered
      under this Plan.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this Plan, without notice, if Electrify America determines or suspects, in its sole discretion, that
      you: (a) are in violation of the Electrify America Terms of Use, these Plan Terms and Conditions, or any other
      contract between you and Electrify America; (b) have engaged in charging sessions that are excessive, fraudulent,
      or otherwise indicate an unauthorized or unintended use of this Plan; or (c) have engaged in any illegal,
      fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify America services, the
      Electrify America mobile app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this Plan at any time before its automatic termination date for any
      reason, in its sole discretion, provided that Electrify America will send an email to the address associated with
      your account in the event of any change to the scheduled end date of this Plan. Following the termination or
      expiration of this Plan for any reason, your access to the Plan will immediately cease, and you may not use or
      redeem any remaining portion of the complimentary charging time. At that time, your account will automatically
      convert to a basic Electrify America “Pass” account, at no additional fee or charge to you (neither one-time nor
      recurring). You may, but are not obligated to, sign up for a different type of subscription via the Electrify
      America mobile app.
    </p>
  </div>
</template>

<script>
export default {
  name: '2021-vw-id4-charging-plan-disclosure',
  metaInfo: {
    title: '2021 VW ID.4 Charging Plan Disclosure | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/2021-vw-id4-charging-plan-disclosure/' }],
  },
};
</script>
